
import "../css/components/audio.css";
import { Options, Vue} from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

@Options({
  props: {
    musicUrl: String,
    playOnLoad:Boolean
  }
})

export default class AudioPlayer extends Vue {
  @Watch('musicUrl')
  musicChanged(newVal:string, oldVal:string) {
    this.setMusic();
  };
  musicUrl: string = "";
  cd:HTMLElement = null;
  playOnLoad:boolean = false;
  audio:HTMLAudioElement = null;
  head:HTMLElement = null;
  private delayTimer:TimerStruct = {
    count:800,
    id:null
  }
  mounted(){
    this.cd = document.getElementById('cd');
    this.head = document.getElementById('head');
  }
  public setMusic(){
    if(this.audio){this.audio.pause();this.audio.remove()};
    this.audio = new Audio(this.musicUrl);
    this.audio.loop = false;
    this.audio.oncanplay = this.loadOk.bind(this);
    this.audio.onerror = this.loadError.bind(this);
    this.audio.onplay = this.cdPlay.bind(this);
    this.audio.onpause = this.cdPaused.bind(this);
    this.audio.onended = ()=>{
      this.playAuto();
    };
  }
  private cdPlay(){
    clearTimeout(this.delayTimer.id);
    this.delayTimer.id = setTimeout(()=>{
        this.cd.classList.add("play");
    },this.delayTimer.count);
    this.head.classList.remove("headClose");
    this.head.classList.add("headStart")
  }
  private cdPaused(){
    clearTimeout(this.delayTimer.id);
    this.cd.classList.remove("play");
    this.head.classList.remove("headStart");
    this.head.classList.add("headClose")
   
  }
  private loadOk(){
    if(this.playOnLoad){
      setTimeout(()=>{
        this.playAuto();
      },1000);
    }
  }
  public playOrPaused(){
    if(this.audio && this.audio.readyState == 4){
        if(this.audio.paused){
          this.playAuto();
        }else{
          this.pausedAudio();
        }
    }
  }
  private playAuto(){
    if(this.audio){
      this.audio.play();
    }
  }
  private pausedAudio(){
    if(this.audio){
      this.audio.pause();
    }
  }
  private loadError(){
    if (this.audio) {
      this.audio.remove();
    }
  }
  public unmounted(){
    this.pausedAudio();
    if(this.audio)
    this.audio.remove();
  }
}
