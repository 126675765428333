
import store from '@/store';
import { Options, Vue } from 'vue-class-component'
import "../css/Login/Login.css";
import { ElMessage } from 'element-plus'
import HTTP from '@/Utils/HTTP';
import LoginServe from "../components/LoginServe.vue";
import { HasLogin } from '@/App/AppData';
@Options({
  components: {
    LoginServe
  }
})
export default class Login extends Vue{
   userName:string = "";
   passWord:string = "";
   requestLoging:boolean = false;
   private nextTimer:NodeJS.Timer;
   private toNext:number;
   private time:{t?:number,h?:number,m?:number,s?:number} = {};
   private show:boolean =true;
   public mounted(){
       store.state.UserData.setToken(null);
   }
   public unmounted(){
        ElMessage.closeAll();
        clearInterval(this.nextTimer);
        clearInterval(this.calcTimer);
   }
   private calcTimer:NodeJS.Timer;
   public finishView(){
       if (store.state.AppData.endTime) {
            let endT = new Date(store.state.AppData.endTime).getTime();
            let t = new Date(endT-Date.now());
            let today =  Math.floor(t.getTime()/86400000);
            this.time = {
                t:today,
                h:t.getUTCHours(),
                m:t.getUTCMinutes(),
                s:t.getUTCSeconds()
            }
            this.calcTimer = setInterval(() => {
                    let t = new Date(endT-Date.now());
                    let today =  Math.floor(t.getTime()/86400000);
                    this.time = {
                        t:today,
                        h:t.getUTCHours(),
                        m:t.getUTCMinutes(),
                        s:t.getUTCSeconds()
                    }
            }, 1000);  
            if(store.state.AppData.isLogin == HasLogin.NO){
                this.toNext = 3;
                this.nextTimer = setInterval(() => {
                    this.toNext--;
                    if (this.toNext == -1) {
                        clearInterval(this.nextTimer);
                        this.$router.push("/");
                    }
                }, 1000);
            }
       } 
   }
   public destoryView(){
       this.$router.push("/");
   }
   public async toPreview(){
       if(!this.userName || !this.passWord){
            ElMessage.error('账号密码不能为空');
            return;
       }
       this.requestLoging = true;
       let result = await HTTP.instance.Request('post','/login/user',{userName:this.userName,passWord:this.passWord}).catch(()=>{ElMessage.error("请求服务器失败")})
       if(!result){
           this.requestLoging = false;
           return;
       }
       if(result.data.resultCode == 0){
           ElMessage.success(`'${result.data.data.nickName}'欢迎回来,正在前往`);
           store.state.UserData.setToken(result.data.data.token);
           setTimeout(()=>{
               this.show = false;
           },1000)
       }else{
           ElMessage.error(result.data.msg);
           this.requestLoging = false;
       }    
   }
}
