import router from "@/router";
import store from "@/store";
import { Axios, AxiosRequestConfig, Method } from "axios";
import { ElMessage } from "element-plus";
export interface HttpMessage{
    code: number,
    data:{
        resultCode:number,
        msg:string,
        data:any
    }
}
export default class HTTP{
    private axios:Axios;
    private constructor(){}
    private static _instance:HTTP;
    public static get instance():HTTP{
        if(!HTTP._instance){
            HTTP._instance = new HTTP();
        }
        return HTTP._instance;
    }
    private static BaseUrl:string;
    public setBaseUrl(url:string){
        HTTP.BaseUrl = url;
        let config:AxiosRequestConfig = {timeout:20*1000};
        this.axios =  new Axios(config);
        this.axios.interceptors.request.use(
            config=>config,
            err => {
                return Promise.reject(err);
            }
        );
        this.axios.interceptors.response.use(function (response) {
            let data = <HttpMessage>JSON.parse(response.data);
            if(data.data.resultCode == 401){
                store.state.UserData.setToken(null);
                ElMessage.error(data.data.msg);
                setTimeout(()=>{
                    router.push("/login");
                },3000);
                return null;
            }else if(data.data.resultCode == 400){
                store.state.UserData.setToken(null);
                ElMessage.error(data.data.msg);
                setTimeout(()=>{
                    router.push("/login");
                },3000);
                return null;
            }
            return data;
          }, function (error) {
            return Promise.reject(error);
        })
    }
    public Request(method:Method,route:string,params?:Object,isJson:boolean = true,onUploadProgress?:(progressEvent: any) => void,onDownloadProgress?:(progressEvent: any) => void):Promise<HttpMessage>{
        switch(method.toLocaleLowerCase()){
            case 'post':return this.axios.post(HTTP.BaseUrl+route,isJson?JSON.stringify(params):params,{
                onUploadProgress:onUploadProgress,
                onDownloadProgress:onDownloadProgress,
                headers:{
                    Authorization:"Bearer " + store.state.UserData.token,
                }
            });
            case 'get':
                {
                    if (params && typeof params === 'object') {
                        // params拆解成字符串
                        params = Object.keys(params)
                            .map(key => {
                                return (
                                    encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
                                );
                            })
                            .join('&');
                    }
                    let url = params ? `${HTTP.BaseUrl}${route}?${params}` : HTTP.BaseUrl + route;
                    return this.axios.get(url,{
                        headers:{
                            Authorization:"Bearer " + store.state.UserData.token
                        }
                    })
            }
        }
    }   
}