import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Index from '../views/Index.vue'
import Login from '../views/Login.vue'
import NotFound from '../views/NotFound.vue'
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: 'Index',
    component: Index
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path:"/:catchAll(.*)",
    name:'notFound',
    component:NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
