
import "../css/components/loginServe/login-serve.css";
import { Vue,Options} from 'vue-class-component';
import store from "@/store";
import { HasLogin } from "@/App/AppData";
@Options({
  props:{
     success:Function
  }
})
export default class LoginServe extends Vue {
  success:Function;
  centerDialogVisible:boolean = true;
  msg:string = "正在验证是否需要登陆...";
  mounted(){
    store.state.AppData.connectServe(()=>{
      if(store.state.AppData.isLogin == HasLogin.FAILED){
        this.msg = "请求服务器失败!请刷新页面重试";
      }else{
        this.success();
      }
    });
  }
}
