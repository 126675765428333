import router from "@/router";
import store from "@/store";
import HTTP, { HttpMessage } from "@/Utils/HTTP";
import { ElMessage } from 'element-plus'

export enum HasLogin{
    NO,
    CHECK,
    YES,
    FAILED
}
export default class AppData{
    public openTime:string = "";
    public endTime:string = "";
    public isLogin:HasLogin = HasLogin.FAILED;
    public async connectServe(cb:Function = null){
        if(this.isLogin !== HasLogin.CHECK){
            this.isLogin = HasLogin.CHECK;
            let result = await HTTP.instance.Request('get','/check/serverlive').catch(()=>{});
            if(result){
                this.isLogin = result.data.data.HasLogin?HasLogin.YES:HasLogin.NO;
                this.openTime = result.data.data.OpenTime.replace(" ","T");
                this.endTime = result.data.data.EndTime.replace(" ","T");
                if(this.isLogin && store.state.UserData.token){
                    result = <HttpMessage>await HTTP.instance.Request('post','/login/token').catch(()=>{});
                    if(result){
                        if(result.data.resultCode == 0){
                            store.state.UserData.setToken(result.data.data.token);
                        }
                    }
                }
            }else{
                this.isLogin = HasLogin.FAILED;
            }
        }
        cb?cb():null;
    }
}