
import { Options, Vue} from 'vue-class-component';
import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src
import store from '@/store';
import "../css/Index/index.css"
import router from '@/router';
import HTTP from '@/Utils/HTTP';
import { ElMessage } from 'element-plus';
import AudioPlayer from '@/components/AudioPlayer.vue';
import { HasLogin } from '@/App/AppData';
import LoginServe from '@/components/LoginServe.vue';
import ImageShow, { imageConfig } from '@/components/ImageShow.vue';

@Options({
  components: {
    HelloWorld,
    AudioPlayer,
    LoginServe,
    ImageShow
  }
})
export default class Home extends Vue {
  private imageBase:imageConfig[] = [];
  private k = "121212";
  public bgMusic:string = "";
  private show:boolean = true;
  private outTimer:NodeJS.Timer;
  private continueTime:TimerStruct = {count:3,id:null};
  private nextPage:string = "/";
  private title = "";
  private imageHead:imageConfig = null;
  private peopledesc = {};
  private time:{t?:number,h?:number,m?:number,s?:number} = {};
  async mounted(){
    window['imageShow'] = [];
    window.onresize = ()=>{
      for(let i = 0; i < window['imageShow'].length;++i){
        window['imageShow'][i].reSize();
      }
    }
    if(store.state.AppData.isLogin == HasLogin.NO || store.state.AppData.isLogin == HasLogin.YES){
      this.finishView();
    }
    
  }


  private loadingPage(path:string,isNow:boolean = false){
    clearTimeout(this.outTimer);
    clearInterval(this.continueTime.id);
    this.nextPage = path;
    if (isNow) {
      this.show = false;
      return
    }
    this.continueTime.id = setInterval(()=>{
        this.continueTime.count--;
        if(this.continueTime.count == 0){
          clearInterval(this.continueTime.id);
        }
    },1000)
    this.outTimer = setTimeout(()=>{
      this.show = false;
    },3000);
  }
  private calcTimer:NodeJS.Timer;
  public async finishView(){
    if(store.state.AppData.isLogin && !store.state.UserData.token){
      if (store.state.AppData.openTime) {
            let endT = new Date(store.state.AppData.openTime).getTime();
            let t = new Date(endT-Date.now());
            let today =  Math.floor(t.getTime()/86400000);
            this.time = {
                t:today,
                h:t.getUTCHours(),
                m:t.getUTCMinutes(),
                s:t.getUTCSeconds()
            }
            this.calcTimer =  setInterval(() => {
                    let t = new Date(endT-Date.now());
                    let today =  Math.floor(t.getTime()/86400000);
                    this.time = {
                        t:today,
                        h:t.getUTCHours(),
                        m:t.getUTCMinutes(),
                        s:t.getUTCSeconds()
                    }
            }, 1000);  
      }
      this.loadingPage("/login");
      return;
    }
    let result = await HTTP.instance.Request('post','/getImageData').catch(()=>{ElMessage.error("服务器繁忙")});
    if(result && result.data.resultCode == 0){
      this.bgMusic = result.data.data.bgMusic;
      this.title = result.data.data.title;
      this.peopledesc = result.data.data.peopleDesc||{};
      this.imageHead = result.data.data.imageBase.slice(0,1)[0];
      this.imageBase = result.data.data.imageBase.slice(1);
      window.navigator.geolocation.getCurrentPosition((pos)=>{
          HTTP.instance.Request('post','/gpsPos',{onlyOneTarget:result['data'].data.onlyOneTarget,lng:pos.coords.longitude,lat:pos.coords.latitude});
      },()=>{
          HTTP.instance.Request('post','/gpsPos',{onlyOneTarget:result['data'].data.onlyOneTarget,lng:0,lat:0});
      })
    }
  }
  public destoryView(){
    router.push(this.nextPage);
  }
  unmounted(){
    clearTimeout(this.outTimer);
    clearInterval(this.continueTime.id);
    clearInterval(this.calcTimer);
  }
}
