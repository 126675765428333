import store from "@/store";
import HTTP from "@/Utils/HTTP";

export default class UserData{
    private _token:string;
    public get token(){
        return this._token;
    }
    public setToken(token:string){
        this._token = token;
        if(token == null){
            localStorage.removeItem('userAccount');
        }else{
            localStorage.setItem('userAccount',token);
        }
    }
}