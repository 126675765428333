import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import UserData from '@/App/UserData'

import {Edit,User,Lock} from "@element-plus/icons"
import RouterController from './router/RouterController'
import HTTP from './Utils/HTTP'
import { Vue } from 'vue-class-component'
// import VConsole from 'vconsole'


// //var vconsole = new VConsole();




var app = createApp(App);
App.created = function(){
    HTTP.instance.setBaseUrl("https://local.coolarr.com:26400");
    let runTimeEnv = "";
    if(window.navigator['userAgentData']){
        runTimeEnv = window.navigator['userAgentData'].platform;
    }else{
        runTimeEnv = window.navigator.platform;
    }
    store.state.isPc = runTimeEnv == "Windows" || runTimeEnv == "Win32"? true:false;
    store.state.UserData.setToken(localStorage.getItem('userAccount'));
}
app.component(Lock.name,Lock);
app.component(User.name,User);
app.use(ElementPlus);
app.use(store).use(router).mount('#app');
new RouterController(router);


