import AppData from '@/App/AppData'
import UserData from '@/App/UserData'
import { createStore } from 'vuex'

export default createStore({
  state: {
    UserData:new UserData(),
    AppData:new AppData(),
    isPc:false
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
